import React, { useEffect, useState, useContext } from "react";
//router
import { useParams, useLocation } from "react-router-dom";
// get culture from utils
import { Card, Container, UncontrolledTooltip, CardHeader } from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader";
import Table from "components/common/CustomTable";
import useApi from "../../hooks/useApi";
import shaheenPayApi from "api/ShaheenSp";
import Spinner from "../../components/Spinner";
import { useForm } from "react-hook-form";
import TextPopup from "components/common/TextModal";
import Pagination from "components/common/Pagination";
import useAlert from "hooks/useAlert";
import SearchModal from "components/common/SearchModal";
import useSearchPopup from "hooks/useSearchPopup";
import usePagination from "hooks/usePagination";
import { PaginationContext } from "context/PaginationContext";
import PayPopup from "./components/PayPopup";
import useAbility from "hooks/useAbility";
import content from "./content";
import commenContent from "components/common/content";
import env from "env";
// router
import { useRouteMatch } from "react-router-dom";

// get culture from utils
import getCulture from "utils/getCulture";
import usePrevRequest from "hooks/usePrevRequest";
const ShaheenPay = () => {
  const rout = useRouteMatch();
  const culture = getCulture(rout.url);

  const [headers, setHeaders] = useState([
    commenContent.actions[culture],
    content.spCode[culture],
    content.orderId[culture],
    content.followUpStatus[culture],
    content.customerCode[culture],
    content.customerName[culture],
    content.rmbAmount[culture],
    commenContent.country[culture],
    commenContent.branch[culture],
  ]);

  const [columns, setColumns] = useState([
    "spCode",
    "orderNumber",
    "followUpStatusText",
    "customer.customerCode",
    "customer.customerName",
    "rmB_Amount",
    "country.name",
    "branch.name",
  ]);

  // This varibale holds the page name so when the user navigate to 'air recived details'
  // and then came back i will set him back when he was
  const pageName = `shaheen-pay-${shipmentLowerCase}`;
  const { pageNo, setPageNo, docsPerPage, setDocsPerPage, prevPageCheck } =
    usePagination(pageName);

  const paginationCtx = useContext(PaginationContext);
  const { shipmentType } = useParams();
  const location = useLocation();

  const shipmentLowerCase = shipmentType.toLowerCase();

  // Apis
  const getAllPays = useApi(shaheenPayApi.getAllPays);
  const getByColumn = useApi(shaheenPayApi.getByColumnPay);
  const payInvoice = useApi(shaheenPayApi.payInvoice);

  // States
  const [data, setData] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [payModal, setPayModal] = useState(false);
  const [orderNumber, setOrderNumber] = useState("");
  const [rowId, setRowId] = useState("");

  const permission =
    shipmentType.toLowerCase() === "air"
      ? "SPAir"
      : shipmentType.toLowerCase() === "lcl"
      ? "SPLcl"
      : "SPFcl";

  const checkAbility = useAbility(permission);

  // useAlert custom hook
  const { alert, sweetAlert } = useAlert();

  const {
    handleSubmit: paySubmit,
    formState: { errors: payError },
    control: payControler,
    reset: resetPay,
    setValue: paySetValue,
  } = useForm();

  const editResponse = (item) => {
    item.orderNumber = `${item.country?.countryCode}-${item.orderNumber}${
      shipmentLowerCase === "air" ? "AC" : "LC"
    }`;
    item.followUpStatusText = env.followUpStatus[item.followUpStatus - 1][culture];
    return item;
  };

  const searchPopup = useSearchPopup(
    getByColumn,
    headers,
    columns,
    data,
    editResponse
  );

  const updateData = (arr) => {
    // editedResponse.forEach((response) => editResponse(response));
    setData(arr);
  };

  const getPays = async () => {
    const res = await getAllPays.request(
      shipmentLowerCase,
      pageNo,
      docsPerPage
    );

    if (res.status === 200) {
      // const field = shipmentLowerCase === "air" ? "airCargo" : "lclCargo";
      const field = `${shipmentLowerCase}Cargo`;
      res.data.data[field].forEach(editResponse);
      setData(res.data.data[field]);

      // if the prev page is not the same as this one clear the context
      if (!prevPageCheck()) paginationCtx.resetPagination();
    }
  };

  const handlePay = (obj) => {
    paySetValue("id", obj.id);
    setOrderNumber(obj.orderNumber.match(/(\d+)/)[0]);
    setRowId(obj.id);
    setPayModal(true);
  };

  const payFunctionality = async (formData) => {
    setSpinner(true);
    setPayModal(false);
    const schema = {
      ...formData,
      rmB_Amount: +formData.rmB_Amount,
      orderNumber,
      // cargoType: +shipmentType,
    };

    const res = await payInvoice.request(schema, shipmentLowerCase);

    if (res.status === 200) {
      sweetAlert(commenContent.done[culture]);
      const newData = data.filter(
        (item) => item.orderNumber.match(/(\d+)/)[0] !== orderNumber
      );
      setData(newData);
    }
    resetPay();
    setOrderNumber("");
    setSpinner(false);
  };

  const handleCancel = () => {
    setPayModal(false);
    resetPay();
  };

  const payRendrer = (obj) => checkAbility("create") && !obj.spCode;

  usePrevRequest(
    getPays,
    searchPopup,
    pageNo,
    docsPerPage,
    updateData,
    prevPageCheck,
    "",
    "",
    shipmentType
  );

  return (
    <div>
      {alert}
      {getAllPays.errorAlert}
      {payInvoice.errorAlert}
      {spinner ? <Spinner gate="#29bb64" bg="#fff" opacity /> : null}
      {getAllPays.loading ? (
        <Spinner gate="#29bb64" bg="#fff" />
      ) : (
        <>
          <SimpleHeader
            parents={[
              `${content.shaheenPay[culture]} ${" "} ${
                content[shipmentLowerCase][culture]
              }`,
            ]}
          />
          <Container className="mt--6" fluid>
            <Card>
              <CardHeader>
                <h3 className={`mb-0`}>
                  {content.shaheenPay[culture]}{" "}
                  {content[shipmentLowerCase][culture]}
                </h3>
                {/* <h3 className={`mb-0 text-md-left`}>
                    {content.shaheenPay[culture]}{" "}
                    {culture === "ar" ? "الجوي" : "Air"}
                  </h3> */}
              </CardHeader>
              <Pagination
                dataLength={data?.length}
                // dataLength={searchPopup.isSelected ? searchPopup.hookData?.length : data.data?.length }
                // when the user search using the popup and select a row
                // after then only use the pagination states from searchPopup hook
                pageNo={searchPopup.isSelected ? searchPopup.pageNo : pageNo}
                setPageNo={
                  searchPopup.isSelected ? searchPopup.setPageNo : setPageNo
                }
                docsPerPage={
                  searchPopup.isSelected ? searchPopup.docsPerPage : docsPerPage
                }
                setDocsPerPage={
                  searchPopup.isSelected
                    ? searchPopup.setDocsPerPage
                    : setDocsPerPage
                }
              >
                <Table
                  pageName={pageName}
                  headers={headers}
                  columns={columns}
                  setHeaders={setHeaders}
                  setColumns={setColumns}
                  // when the user search using the popup and select a row
                  // after then only use the hookData states from searchPopup hook
                  data={data}
                  setData={setData}
                  handlePay={handlePay}
                  payRendrer={payRendrer}
                  onHeaderClick={searchPopup.handleHeaderClicked}
                  // when the user was searching useing the popup and select a row
                  // store the popup pagination in the context
                  // otherwise store the normal pagination in the context
                  setPagination={
                    searchPopup.isSelected
                      ? (rowId) =>
                          paginationCtx.setPagination(
                            searchPopup.pageNo,
                            searchPopup.docsPerPage,
                            rowId,
                            pageName
                          )
                      : (rowId) =>
                          paginationCtx.setPagination(
                            pageNo,
                            docsPerPage,
                            rowId,
                            pageName
                          )
                  }
                >
                  <span className="me-1" fun="handlePay" condition="payRendrer">
                    <div id="pay" className="table-action cursor-pointer">
                      <i className="fas fa-dollar-sign fa-lg hover-success" />
                    </div>
                    <UncontrolledTooltip delay={0} target="pay">
                      {commenContent.pay[culture]}
                    </UncontrolledTooltip>
                  </span>
                </Table>
              </Pagination>
              <TextPopup
                modal={payModal}
                text={commenContent.confirm[culture]}
                handleCancel={handleCancel}
                // fn={editAirCargo}
                color="success"
                // disabled={!formIsEdited && !editSchema?.imgs}
                fn={paySubmit(payFunctionality)}
              >
                <Container>
                  <PayPopup
                    control={payControler}
                    errors={payError}
                    orderNumber={orderNumber}
                    rowId={rowId}
                    setValue={paySetValue}
                  />
                </Container>
              </TextPopup>
            </Card>
          </Container>
          {searchPopup.openPopup && (
            <SearchModal
              data={data}
              setData={setData}
              updateData={updateData}
              searchPopup={searchPopup}
              editResponse={editResponse}
              popupSearchCtx={paginationCtx.popupSearch}
              extraField={{
                header: content.orderId[culture],
                column: "orderNumber",
              }}
            />
          )}
        </>
      )}
    </div>
  );
};

export default ShaheenPay;
