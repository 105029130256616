import jwt_decode from "jwt-decode";

function useAuth() {
  const authUser =
    localStorage.getItem(btoa("token")) !== null
      ? jwt_decode(localStorage.getItem(btoa("token")))
      : {};

  return authUser;
}

export default useAuth;
