import React, { useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Button,
  UncontrolledTooltip,
  Form,
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader";
import { useForm, Controller } from "react-hook-form";
import Spinner from "../../components/Spinner";
import useApi from "../../hooks/useApi";
import useAlert from "hooks/useAlert";
import TextPopup from "components/common/TextModal";
import content from "./content";
import commenContent from "components/common/content";
import useCulture from "hooks/useCulture";
import AdvanceTable from "components/common/AdvanceTable";
import useAdvanceSearch from "hooks/useAdvanceSearch";
import Pagination from "components/common/Pagination";
import minimumCharge from "api/minimumCharge";
import useCheckRequest from "hooks/useCheckRequest";
import AdvanceSearchModal from "components/common/AdvanceSearchModal";
import EditCharge from "./components/EditCharge";
import env from "env";
import CountryTable from "components/common/CountryTable";

const MinimumCharge = () => {
  const culture = useCulture();

  const [data, setData] = useState([]);
  const [rowId, setRowId] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [activateModal, setActivateModal] = useState(false);
  const [countryTable, setCountryTable] = useState(false);
  const { alert, sweetAlert } = useAlert();

  const [headers, setHeaders] = useState([
    commenContent.actions[culture],
    commenContent.country[culture],
    content.shippingType[culture],
    commenContent.priceType[culture],
    content.minimumWightKg[culture],
    content.airMinimumPrice[culture],
    content.minimumCBM[culture],
    content.lclMinimumPrice[culture],
  ]);

  const [columns, setColumns] = useState([
    "country.name",
    "shippingTypeText",
    "priceTypeText",
    "minimumWightKg",
    "airMinimumPrice",
    "minimumCBM",
    "lclMinimumPrice",
  ]);

  const minimumChargesApi = useApi(minimumCharge.minimumCharges);
  const getByColumn = useApi(minimumCharge.filter);
  const createOneApi = useApi(minimumCharge.createOne);
  const activateApi = useApi(minimumCharge.activate);
  const updateOneApi = useApi(minimumCharge.updateOne);
  const deleteOneApi = useApi(minimumCharge.deleteOne);

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm();

  const {
    handleSubmit: editHandleSubmit,
    formState: { errors: editErrors },
    control: editControl,
    reset: editReset,
    setValue: editSetValue,
  } = useForm();

  const editResponse = (row) => {
    row.priceTypeText = env.priceType[row.priceType - 1];
    row.shippingTypeText = env.shippingType[row.shippingType - 1];
  };

  const pageName = "minimum-charge";

  // search popup
  const {
    key,
    setKey,
    openPopup,
    setOpenPopup,
    pageNo,
    setPageNo,
    docsPerPage,
    setDocsPerPage,
    register,
    onHeaderClick,
    searchHandler,
    setSearchField,
    header,
    isRemove,
    setIsRemove,
    handleRemove,
  } = useAdvanceSearch(pageName, editResponse);

  const getData = async () => {
    const res = await minimumChargesApi.request(pageNo, docsPerPage);
    if (res.status === 200) {
      res.data.data.forEach(editResponse);
      setData(res.data.data);
    }
  };

  const handleSearch = async () => {
    setSpinner(true);
    const res = await searchHandler(getByColumn, pageNo, docsPerPage);
    if (res.ok) {
      setData(res.data.data);
      register({ key: 1, pageNo, docsPerPage, pageName });
    }
    setSpinner(false);
  };

  const closeAdvanceSearch = (index) => {
    handleRemove(index, false);
    setOpenPopup(false);
  };

  const handleCreate = async (data) => {
    setSpinner(true);
    const res = await createOneApi.request(data);
    if (res.status === 200) {
      editResponse(res.data.data);
      setData((oldData) => [res.data.data, ...oldData]);
      sweetAlert(commenContent.done[culture]);
      reset();
    }
    setSpinner(false);
  };

  const renderApprove = (obj) => !obj.isActive;

  const handleActivate = (obj) => {
    setRowId(obj.id);
    sweetAlert(content.activatePopup[culture], true, () =>
      activateHandler(obj.id)
    );
  };

  const activateHandler = async (id) => {
    setSpinner(true);
    setActivateModal(false);

    const res = await activateApi.request(id);
    if (res.status === 200) {
      getData();
      sweetAlert(commenContent.done[culture]);
    }
    setSpinner(false);
  };

  const handleEdit = (obj) => {
    setEditModal(true);
    setRowId(obj.id);
    // editSetValue("minimumWightKg", obj.minimumWightKg);
    editSetValue("airMinimumPrice", obj.airMinimumPrice);
    // editSetValue("minimumCBM", obj.minimumCBM);
    editSetValue("lclMinimumPrice", obj.lclMinimumPrice);
    editSetValue("priceType", obj.priceType);
  };

  const editHandler = async (formData) => {
    setSpinner(true);
    setEditModal(false);
    const res = await updateOneApi.request(rowId, formData);
    if (res.status === 200) {
      const newData = [...data];
      const rowIndex = newData.findIndex((item) => item.id === rowId);
      editResponse(res.data.data);
      newData[rowIndex] = { ...res.data.data };
      setData(newData);
      sweetAlert(commenContent.done[culture]);
      editReset();
    }
    setSpinner(false);
  };

  const handleDelete = (obj) => {
    setRowId(obj.id);
    sweetAlert(content.deletePopup[culture], true, () =>
      delelteHamdler(obj.id)
    );
  };

  const delelteHamdler = async (id) => {
    setSpinner(true);
    const res = await deleteOneApi.request(id);
    if (res.status === 200) {
      const newData = data.filter((item) => item.id !== id);
      setData(newData);
      sweetAlert(commenContent.done[culture]);
    }
  };

  const handleCancel = () => {
    setEditModal(false);
    setCountryTable(false);
  };

  useCheckRequest({
    pageNo,
    isRemove,
    setIsRemove,
    requests: [getData, handleSearch],
    setPageNo,
    setDocsPerPage,
    docsPerPage,
    key,
    register,
  });

  if (minimumChargesApi.loading) return <Spinner gate="#29bb64" bg="#fff" />;

  return (
    <>
      {alert}
      {minimumChargesApi.errorAlert}
      {createOneApi.errorAlert}
      {activateApi.errorAlert}
      {updateOneApi.errorAlert}
      {deleteOneApi.errorAlert}
      {spinner ? <Spinner gate="#29bb64" bg="#fff" opacity /> : null}
      <SimpleHeader parents={[content.minimumCharge[culture]]} />
      <Container className="mt--6" fluid>
        <Card className="card-plain">
          <CardHeader>
            <div className="d-flex justify-content-between">
              <h3 className={`mb-0 text-md-left`}>
                {content.minimumCharge[culture]}
              </h3>
            </div>
          </CardHeader>
          <CardBody>
            <Form onSubmit={handleSubmit(handleCreate)}>
              <Row>
                <Col md="6" lg="4">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="country">
                      {commenContent.country[culture]}
                    </label>
                    <Controller
                      control={control}
                      name="country"
                      rules={{ required: "Country feild is required" }}
                      render={({
                        field: { ref, onChange, value, ...field },
                      }) => (
                        <Input
                          {...field}
                          id="country"
                          placeholder={`-- ${commenContent.selectOption[culture]} --`}
                          type="text"
                          className={errors.country && "error"}
                          value={value || ""}
                          onChange={({ target: { value } }) => onChange(value)}
                          onClick={() => setCountryTable(true)}
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
                <Col md="6" lg="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="shipping-type"
                    >
                      {content.shippingType[culture]}
                    </label>
                    <Controller
                      control={control}
                      name="shippingType"
                      rules={{
                        required: "shipping Type field is required",
                      }}
                      render={({
                        field: { ref, onChange, value, ...field },
                      }) => (
                        <Input
                          {...field}
                          id="shipping-type"
                          type="select"
                          className={errors.shippingType && "error"}
                          defaultValue={true}
                          onChange={({ target: { value } }) => {
                            // setPriceValue(value)
                            onChange(Number(value));
                          }}
                        >
                          <option value={true} disabled>
                            {`-- ${commenContent.selectOption[culture]} --`}
                          </option>
                          {env.shippingType.map((item, i) => (
                            <option value={i + 1} key={i}>
                              {item}
                            </option>
                          ))}
                        </Input>
                      )}
                    />
                  </FormGroup>
                </Col>
                <Col md="6" lg="4">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="priceType">
                      {commenContent.priceType[culture]}
                    </label>
                    <Controller
                      control={control}
                      name="priceType"
                      rules={{
                        required: "priceType value field is required",
                      }}
                      render={({
                        field: { ref, onChange, value, ...field },
                      }) => (
                        <Input
                          {...field}
                          id="priceType"
                          type="select"
                          className={errors.priceType && "error"}
                          value={value || ""}
                          onChange={({ target: { value } }) => onChange(+value)}
                        >
                          <option value="" disabled>
                            -- {commenContent.selectAnOption[culture]} --
                          </option>
                          {env.priceType.map((price, index) => (
                            <option value={index + 1} key={index}>
                              {price}
                            </option>
                          ))}
                        </Input>
                      )}
                    />
                  </FormGroup>
                </Col>
                {/* <Col md="6" lg="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="minimumWightKg"
                    >
                      {content.minimumWightKg[culture]}
                    </label>
                    <Controller
                      control={control}
                      name="minimumWightKg"
                      rules={{
                        required: "minimumWightKg value field is required",
                        pattern: {
                          value: /^(0|[1-9]\d*)(\.\d+)?$/,
                        },
                      }}
                      render={({
                        field: { ref, onChange, value, ...field },
                      }) => (
                        <Input
                          {...field}
                          id="price-value"
                          placeholder="0"
                          type="number"
                          className={errors.minimumWightKg && "error"}
                          value={value || ""}
                          onChange={({ target: { value } }) => onChange(value)}
                          onWheel={(e) => e.target.blur()}
                        />
                      )}
                    />
                  </FormGroup>
                </Col> */}
                <Col md="6" lg="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="airMinimumPrice"
                    >
                      {content.airMinimumPrice[culture]}
                    </label>
                    <Controller
                      control={control}
                      name="airMinimumPrice"
                      rules={{
                        required: "airMinimumPrice value field is required",
                        pattern: {
                          value: /^(0|[1-9]\d*)(\.\d+)?$/,
                        },
                      }}
                      render={({
                        field: { ref, onChange, value, ...field },
                      }) => (
                        <Input
                          {...field}
                          id="airMinimumPrice"
                          placeholder="0"
                          type="number"
                          className={errors.airMinimumPrice && "error"}
                          value={value || ""}
                          onChange={({ target: { value } }) => onChange(value)}
                          onWheel={(e) => e.target.blur()}
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
                {/* <Col md="6" lg="4">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="minimumCBM">
                      {content.minimumCBM[culture]}
                    </label>
                    <Controller
                      control={control}
                      name="minimumCBM"
                      rules={{
                        required: "minimumCBM value field is required",
                        pattern: {
                          value: /^(0|[1-9]\d*)(\.\d+)?$/,
                        },
                      }}
                      render={({
                        field: { ref, onChange, value, ...field },
                      }) => (
                        <Input
                          {...field}
                          id="minimumCBM"
                          placeholder="0"
                          type="number"
                          className={errors.minimumCBM && "error"}
                          value={value || ""}
                          onChange={({ target: { value } }) => onChange(value)}
                          onWheel={(e) => e.target.blur()}
                        />
                      )}
                    />
                  </FormGroup>
                </Col> */}
                <Col md="6" lg="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="lclMinimumPrice"
                    >
                      {content.lclMinimumPrice[culture]}
                    </label>
                    <Controller
                      control={control}
                      name="lclMinimumPrice"
                      rules={{
                        required: "lclMinimumPrice value field is required",
                        pattern: {
                          value: /^(0|[1-9]\d*)(\.\d+)?$/,
                        },
                      }}
                      render={({
                        field: { ref, onChange, value, ...field },
                      }) => (
                        <Input
                          {...field}
                          id="lclMinimumPrice"
                          placeholder="0"
                          type="number"
                          className={errors.lclMinimumPrice && "error"}
                          value={value || ""}
                          onChange={({ target: { value } }) => onChange(value)}
                          onWheel={(e) => e.target.blur()}
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Button color="success" type="submit">
                <span className="btn-inner--icon">
                  <i className="ni ni-fat-add" />
                </span>
                <span className="ms-1">{commenContent.create[culture]}</span>
              </Button>
            </Form>
          </CardBody>
        </Card>
        <Card>
          <Pagination
            dataLength={data.length}
            pageNo={pageNo}
            setPageNo={setPageNo}
            docsPerPage={docsPerPage}
            setDocsPerPage={setDocsPerPage}
          >
            <AdvanceTable
              pageName={pageName}
              headers={headers}
              columns={columns}
              setColumns={setColumns}
              setHeaders={setHeaders}
              pageNo={pageNo}
              // handleSort={handleSort}
              // when the user search using the popup and select a row
              // after then only use the hookData states from searchPopup hook
              data={data}
              setData={setData}
              renderApprove={renderApprove}
              handleActivate={handleActivate}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
              searchedHeaders={header}
              onHeaderClick={onHeaderClick}
              handleRemove={handleRemove}
            >
              <span
                fun="handleDelete"
                condition="renderApprove"
                className="me-1"
              >
                <div id="danger" className="table-action cursor-pointer">
                  <i className="fas fa-trash fa-lg hover-danger" />
                </div>
                <UncontrolledTooltip delay={0} target="danger">
                  {commenContent.delete[culture]}
                </UncontrolledTooltip>
              </span>
              <span fun="handleActivate" condition="renderApprove" className="me-1">
                <div id="details1" className="table-action cursor-pointer">
                  <i className="fas fa-check fa-lg hover-success"></i>
                </div>
                <UncontrolledTooltip delay={0} target="details1">
                  {content.activate[culture]}
                </UncontrolledTooltip>
              </span>
              <span fun="handleEdit" condition="renderApprove">
                <div id="edit1" className="table-action cursor-pointer">
                  <i className="fas fa-pencil-alt fa-lg hover-info" />
                </div>
                <UncontrolledTooltip delay={0} target="edit1">
                  {commenContent.edit[culture]}
                </UncontrolledTooltip>
              </span>
            </AdvanceTable>
          </Pagination>
        </Card>
      </Container>
      {openPopup && (
        <AdvanceSearchModal
          setKey={setKey}
          openPopup={openPopup}
          pageName={pageName}
          getByColumn={getByColumn}
          updateData={setData}
          setParentPageNo={setPageNo}
          setParentDocsPerPage={setDocsPerPage}
          setParentSearchField={setSearchField}
          handleClose={closeAdvanceSearch}
          editResponse={editResponse}
        />
      )}
      <TextPopup
        modal={activateModal}
        text={content.activate[culture]}
        handleCancel={() => setActivateModal(false)}
        fn={activateHandler}
        color="success"
      >
        <CardBody>{content.activatePopup[culture]}</CardBody>
      </TextPopup>
      <TextPopup
        modal={editModal}
        text={content.activate[culture]}
        handleCancel={handleCancel}
        fn={editHandleSubmit(editHandler)}
        color="success"
      >
        <EditCharge control={editControl} errors={editErrors} />
      </TextPopup>
      <TextPopup
        modal={countryTable}
        text={commenContent.next[culture]}
        handleCancel={handleCancel}
        fn={() => {}}
        color="primary"
        noBtn
      >
        <Container>
          <CountryTable
            // setCountry={setCountry}
            setCountryTable={setCountryTable}
            setValue={setValue}
            sweetAlert={sweetAlert}
          />
        </Container>
      </TextPopup>
    </>
  );
};

export default MinimumCharge;
