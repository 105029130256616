export default {
  orderId: {
    ar: "معرٌف الطلب",
    en: "Order Id",
  },
  customerCode: {
    ar: "كود الزبون",
    en: "Customer Code",
  },
  customerName: {
    ar: "اسم الزبون",
    en: "Customer Name",
  },
  rmbAmount: {
    ar: "المقدار بالعملة الصينية",
    en: "RMP Amount"
  },
  usdAmount: {
    ar: "المقدار بالعملة الامريكية",
    en: "Usd Amount"
  },
  spCode: {
    ar: "كود الsp",
    en: "spCode"
  },
  shaheenPay: {
    ar: "شاهين باي",
    en: "Shaheen Pay"
  },
  currency: {
    ar: "العملة",
    en: "Currency"
  },
  yuanValue: {
    ar: "قيمة اليوان",
    en: "Yuan value"
  },
  air: {
    ar: "جوي",
    en: "Air"
  },
  lcl: {
    ar: "LCL",
    en: "LCL"
  },
  fcl: {
    ar: "FCL",
    en: "FCL"
  },
  followUpStatus: {
    ar: "حالة الشحنة",
    en: "Follow up status",
  },
};
