import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Button,
  CardHeader,
  UncontrolledTooltip,
} from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import { useHistory, useRouteMatch } from "react-router-dom";
import SimpleHeader from "components/Headers/SimpleHeader";
import useApi from "hooks/useApi";
import Spinner from "../../../components/Spinner";
import TextPopup from "components/common/TextModal";
import useAlert from "hooks/useAlert";
import usePagination from "hooks/usePagination";
import Pagination from "components/common/Pagination";
import Table from "components/common/CustomTable";
import useSearchPopup from "hooks/useSearchPopup";
import SearchModal from "components/common/SearchModal";
import EditCustomer from "./components/EditCustomer";
import customer from "api/customer";
import useAbility from "hooks/useAbility";
import getCulture from "utils/getCulture";
import commonContent from "components/common/content";
import env from "env";
import content from "./content";
import FilePicker from "components/common/FilePicker";
import useFormData from "hooks/useFormData";

const CustomerSettings = () => {
  const rout = useRouteMatch();
  const history = useHistory();
  const culture = getCulture(rout.url);
  const headers = [
    commonContent.actions[culture],
    commonContent.name[culture],
    commonContent.code[culture],
    commonContent.phone[culture],
    commonContent.branch[culture],
    commonContent.date[culture],
  ];
  const columns = [
    "customerName",
    "customerCode",
    "phone",
    "currentBranch.name",
    "createdOn",
  ];

  const [data, setData] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [rowId, setRowId] = useState("");
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const checkAbility = useAbility("Customer");
  const { alert, sweetAlert } = useAlert();

  const formDataGenerator = useFormData();

  // use form hook
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    watch,
    setValue,
  } = useForm();

  const {
    handleSubmit: editHandleSubmit,
    formState: { errors: editErrors },
    control: editControl,
    setValue: editSetValue,
  } = useForm();

  const { pageNo, setPageNo, docsPerPage, setDocsPerPage } = usePagination("");

  const customersApi = useApi(customer.preload);
  const createCustomerApi = useApi(customer.createCustomer);
  const getByColumn = useApi(customer.getByColumn);
  const deleteCustomerApi = useApi(customer.deleteCustomer);
  const updateCustomerApi = useApi(customer.updateCustomer);

  const editResponse = (item) => {
    item.createdOn = item.createdOn.split("T")[0];
    return item;
  };

  const searchPopup = useSearchPopup(
    getByColumn,
    headers,
    columns,
    data,
    editResponse
  );

  const createCustomer = async (formData) => {
    setSpinner(true);
    const schema = formDataGenerator(formData);

    const res = await createCustomerApi.request(schema);
    if (res.status === 200) {
      setData((d) => ({ ...d, data: [res.data.data, ...d.data] }));
      sweetAlert("Done");
      reset();
    }
    setSpinner(false);
  };

  const getCustomers = async () => {
    const res = await customersApi.request(pageNo, docsPerPage);

    if (res.status === 200) {
      res.data.data.data.forEach(editResponse);
      setData(res.data.data);
    }
  };

  const updateData = (arr) => {
    setData((d) => ({ ...d, data: arr }));
  };

  const handleCancel = () => {
    setRowId("");
    setEditModal(false);
    setDeleteModal(false);
  };

  const deleteRow = async () => {
    setDeleteModal(false);
    setSpinner(true);
    const res = await deleteCustomerApi.request(rowId);
    if (res.status === 200) {
      const newData = data.data.filter((item) => item.id !== rowId);
      setData((d) => ({ ...d, data: newData }));
      sweetAlert("Done");
    }
    setSpinner(false);
  };

  const handleEdit = (obj) => {
    setRowId(obj.id);
    editSetValue("customerName", obj.customerName);
    editSetValue("phone", obj.phone);
    editSetValue("priceType", obj.priceType);
    editSetValue("currentBranchId", obj.currentBranch?.id || "");
    setEditModal(true);
  };

  const editCustomerHandler = async (formData) => {
    setEditModal(false);
    setSpinner(true);
    const res = await updateCustomerApi.request(rowId, formData);

    if (res.status === 200) {
      const newData = [...data.data];
      const rowIndex = newData.findIndex((item) => item.id === rowId);
      newData[rowIndex] = res.data.data;
      setData((d) => ({ ...d, data: newData }));
      sweetAlert("Done");
    }
    setRowId("");
    setSpinner(false);
  };

  const handleNavigate = (obj) => {
    history.push(`/${culture}-admin/userdetails/1/10/${obj.applicationUserId}`);
  };

  const editRenderer = () => checkAbility("edit");

  const images = watch("image") || {};

  useEffect(() => {
    getCustomers();
  }, [pageNo, docsPerPage]);

  if (customersApi.loading) return <Spinner gate="#29bb64" bg="#fff" />;

  return (
    <div>
      {alert}
      {customersApi.errorAlert}
      {createCustomerApi.errorAlert}
      {deleteCustomerApi.errorAlert}
      {updateCustomerApi.errorAlert}
      {getByColumn.errorAlert}
      {spinner && <Spinner gate="#29bb64" bg="#fff" opacity />}
      <SimpleHeader parents={[content.page[culture]]} backBtn />
      <Container className="mt--6" fluid>
        <Card className="card-plain">
          <CardHeader>
            <div className="d-flex justify-content-between">
              <h3 className={`mb-0 text-md-left`}>{content.page[culture]}</h3>
            </div>
          </CardHeader>
          {checkAbility("create") && (
            <CardBody>
              <form onSubmit={handleSubmit(createCustomer)}>
                <Row>
                  <Col md="6" lg="4">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="name">
                        {commonContent.name[culture]}
                      </label>
                      <Controller
                        control={control}
                        name="customerName"
                        rules={{
                          required: "name field is required",
                        }}
                        render={({
                          field: { ref, onChange, value, ...field },
                        }) => (
                          <Input
                            {...field}
                            id="name"
                            placeholder={commonContent.name[culture]}
                            type="text"
                            className={errors.customerName && "error"}
                            value={value || ""}
                            onChange={({ target: { value } }) =>
                              onChange(value)
                            }
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6" lg="4">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="phone">
                        {commonContent.phone[culture]}
                      </label>
                      <Controller
                        control={control}
                        name="phone"
                        rules={{
                          pattern: {
                            value:
                              /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
                            message: "only english character allowed",
                          },
                        }}
                        render={({
                          field: { ref, onChange, value, ...field },
                        }) => (
                          <Input
                            {...field}
                            id="phone"
                            placeholder={commonContent.phone[culture]}
                            type="text"
                            className={errors.phone && "error"}
                            value={value || ""}
                            onChange={({ target: { value } }) =>
                              onChange(value)
                            }
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6" lg="4">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="priceType">
                        {commonContent.type[culture]}
                      </label>
                      <Controller
                        control={control}
                        name="priceType"
                        rules={{ required: true }}
                        render={({
                          field: { ref, onChange, value, ...field },
                        }) => (
                          <Input
                            {...field}
                            id="priceType"
                            type="select"
                            className={errors.priceType && "error"}
                            value={value || "disabled"}
                            onChange={({ target: { value } }) =>
                              onChange(+value)
                            }
                          >
                            <option disabled value="disabled">
                              -- {commonContent.selectAnOption[culture]} --
                            </option>
                            {env.customerType.map((type, i) => (
                              <option value={i + 1} key={i + 1}>
                                {type[culture]}
                              </option>
                            ))}
                          </Input>
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6" lg="4">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="userName">
                        {commonContent.userName[culture]}
                      </label>
                      <Controller
                        control={control}
                        name="username"
                        rules={{
                          required: "username field is required",
                        }}
                        render={({
                          field: { ref, onChange, value, ...field },
                        }) => (
                          <Input
                            {...field}
                            id="userName"
                            placeholder={commonContent.userName[culture]}
                            type="text"
                            className={errors.username && "error"}
                            value={value || ""}
                            onChange={({ target: { value } }) =>
                              onChange(value)
                            }
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6" lg="4">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="password">
                        {commonContent.password[culture]}
                      </label>
                      <Controller
                        control={control}
                        name="password"
                        rules={{
                          required: "username field is required",
                        }}
                        render={({
                          field: { ref, onChange, value, ...field },
                        }) => (
                          <Input
                            {...field}
                            id="password"
                            placeholder={commonContent.password[culture]}
                            type="text"
                            className={errors.password && "error"}
                            value={value || ""}
                            onChange={({ target: { value } }) =>
                              onChange(value)
                            }
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6" lg="4">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="userType">
                        {commonContent.userType[culture]}
                      </label>
                      <Controller
                        control={control}
                        name="userType"
                        rules={{
                          required: "user type field is required",
                        }}
                        render={({
                          field: { ref, onChange, value, ...field },
                        }) => (
                          <Input
                            {...field}
                            id="userType"
                            placeholder={commonContent.userType[culture]}
                            type="select"
                            className={errors.userType && "error"}
                            value={value || "disabled"}
                            onChange={({ target: { value } }) =>
                              onChange(value)
                            }
                          >
                            <option disabled value="disabled">
                              -- {commonContent.selectAnOption[culture]} --
                            </option>
                            {env.usersType.map((type, index) => (
                              <option value={index + 1} key={index + 1}>
                                {commonContent[type]?.[culture]}
                              </option>
                            ))}
                          </Input>
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6" lg="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="currentBranchId"
                      >
                        {commonContent.branch[culture]}
                      </label>
                      <Controller
                        control={control}
                        name="currentBranchId"
                        rules={{
                          required: true,
                        }}
                        render={({
                          field: { ref, onChange, value, ...field },
                        }) => (
                          <Input
                            {...field}
                            id="currentBranchId"
                            type="select"
                            className={errors.currentBranchId && "error"}
                            value={value || "disabled"}
                            onChange={({ target: { value } }) =>
                              onChange(value)
                            }
                          >
                            <option disabled value="disabled">
                              -- {commonContent.selectAnOption[culture]} --
                            </option>
                            {data.branches?.map((branch) => (
                              <option value={branch.id} key={branch.id}>
                                {branch.name}
                              </option>
                            ))}
                          </Input>
                        )}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <FilePicker
                  images={images}
                  setImages={(img) => setValue("image", img)}
                />
                <Button
                  color="success"
                  type="submit"
                  disabled={!Object.keys(images).length}
                >
                  <span className="btn-inner--icon mr-1">
                    <i className="ni ni-fat-add" />
                  </span>
                  <span>{commonContent.create[culture]}</span>
                </Button>
              </form>
            </CardBody>
          )}
        </Card>
        <Card>
          <Pagination
            dataLength={data.data?.length}
            pageNo={pageNo}
            setPageNo={setPageNo}
            docsPerPage={docsPerPage}
            setDocsPerPage={setDocsPerPage}
          >
            <Table
              pageName="customes-settings"
              headers={headers}
              columns={columns}
              // when the user search using the popup and select a row
              // after then only use the hookData states from searchPopup hook
              data={data.data}
              setData={setData}
              handleEdit={handleEdit}
              handleNavigate={handleNavigate}
              editRenderer={editRenderer}
              onHeaderClick={searchPopup.handleHeaderClicked}
            >
              <span fun="handleEdit" className="me-1" condition="editRenderer">
                <div id="edit1" className="table-action cursor-pointer">
                  <i className="fas fa-pencil-alt fa-lg hover-info"></i>
                </div>
                <UncontrolledTooltip delay={0} target="edit1">
                  {commonContent.edit[culture]}
                </UncontrolledTooltip>
              </span>
              <span
                fun="handleNavigate"
                // to={`/${culture}-admin/userdetails/${pageNo}/${docsPerPage}`}
              >
                <div id="edit1" className="table-action cursor-pointer">
                  <i className="fas fa-eye fa-lg hover-info"></i>
                </div>
                <UncontrolledTooltip delay={0} target="edit1">
                  {commonContent.showDetails[culture]}
                </UncontrolledTooltip>
              </span>
            </Table>
          </Pagination>
        </Card>
      </Container>
      <TextPopup
        modal={editModal}
        text={commonContent.save[culture]}
        handleCancel={handleCancel}
        fn={editHandleSubmit(editCustomerHandler)}
        color="primary"
      >
        <Container>
          <EditCustomer
            control={editControl}
            errors={editErrors}
            branches={data?.branches || []}
          />
        </Container>
      </TextPopup>
      <TextPopup
        modal={deleteModal}
        text={commonContent.yes[culture]}
        handleCancel={handleCancel}
        fn={deleteRow}
        color="danger"
      >
        <Container>
          <h2>{content.deletePopup[culture]}</h2>
        </Container>
      </TextPopup>
      {searchPopup.openPopup && (
        <SearchModal
          data={data}
          setData={setData}
          updateData={updateData}
          searchPopup={searchPopup}
          editResponse={editResponse}
          // popupSearchCtx={paginationCtx.popupSearch}
          extraField={{ header: "name", column: "customerName" }}
        />
      )}
    </div>
  );
};

export default CustomerSettings;
