import { create } from "apisauce";

const apiClient = create({
  // baseURL: "https://gw.shahen.gw.ly/api",
  baseURL: "https://shippingv2.shahen.ly/api",
  // baseURL: "https://localhost:7083/api"
});

apiClient.addAsyncRequestTransform(async (request) => {
  const authToken = await localStorage.getItem(btoa("token"));
  if (!authToken) return;
  request.headers["Authorization"] = "Bearer " + authToken;
});

export default apiClient;
